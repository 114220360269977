/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'

// CSS
import './Texts.scss'

export const MiddleText = ({ content, className }) => (
  <div className={`text-middle-container container${className ? ` ${className}` : ``}`}>
    <div className="text text-lg-left text-justify font-size-m" dangerouslySetInnerHTML={{ __html: content }} />
  </div>
)

export const LeftText = ({ content }) => (
  <div className="text-left-container container">
    <div dangerouslySetInnerHTML={{ __html: content }} />
  </div>
)