/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

// CSS
import './ErvaringPreviews.scss'

// Images
import orangeArrow from 'img/orange_arrow.svg'

// Components 
import CustomLink from 'components/CustomLink'

// Third Party
import _ from 'lodash'

const ErvaringPreviews = () => {
  const {
    ervaringen: {
      edges: ervaringList
    }
  } = useStaticQuery(graphql`
  {
    ervaringen: allWordpressWpErvaring(limit: 3) {
      edges {
        node {
          acf {
            preview {
              location
              quote
              small_introduction
              title
              image {
                localFile {
                  childImageSharp {
                    fluid(quality: 100, maxWidth: 1920) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
          }
          path
          slug
        }
      }
    }
  }
  `)

  return (
    <div className="ervaring-preview mb-5 py-5 container">
      <h2 className="m-0 font-size-xl font-weight-xl color-text-main">Geholpen klanten</h2>
      <p className="font-size-sm font-weight-xl color-text-contrast">Bekijk onze onlangs gerealiseerde projecten en ervaringen van onze klanten</p>
      <div className="row">
        {_.map(ervaringList, ervaring => (
          <div className="ervaring-single col-12 col-lg-4">
            <CustomLink to={`/ervaringen/${ervaring.node.slug}`}>
              <Img className="ervaring-image" fluid={ervaring.node.acf.preview.image.localFile.childImageSharp.fluid} />
              <div className="row position-relative ervaring-single-black-bar w-80 m-0 py-2 color-background-main">
                <div className="col-10">
                  <p className="m-0 font-size-sm font-weight-xl color-text-light">{ervaring.node.acf.preview.title}</p>
                  <p className="m-0 font-size-sm font-weight-m color-text-light">{ervaring.node.acf.preview.location}</p>
                </div>
                <div className="col-2 d-flex justify-content-center align-items-center">
                  <img alt="" src={orangeArrow} />
                </div>
              </div>
            </CustomLink>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ErvaringPreviews
