import React from 'react'

// Styles
import './CTABanner.scss'

// Images
import whiteArrow from 'img/white_arrow.svg'

// Third Party
import parse from 'html-react-parser'
import CustomLink from 'components/CustomLink'

const CTABanner = ({ data, className }) => {
  return (
    <div className={`ctabanner ${className ? `${className}` : ``}`}>
      <div className="ctabanner-container container d-flex">
        <div className="ctabanner-text text-center">
          {parse(data.banner_text)}
        </div>
        <div className="d-flex justify-content-center">
          <CustomLink to={data.banner_button_text.url} className="px-3 py-2 color-background-contrast d-flex align-items-center ctabanner-button">
            <p className="color-text-light font-weight-xl mb-0 text-uppercase">{data.banner_button_text.title}</p>
            <img alt="arrow" src={whiteArrow} />
          </CustomLink>
        </div>
      </div>
    </div>
  )
}

export default CTABanner