/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

// Images
import orangeArrow from 'img/orange_arrow.svg'

// Components
import Content from 'components/Content'

import './VideoSlider.scss'

// Third Party
import _ from 'lodash'
import Slider from 'react-slick'
import BackgroundImage from 'gatsby-background-image'


export default function VideoSlider({ items, className }) {

  return (
    <div className={`video-slider ${className && `${className}`}`}>
      <Slider 
        dots
        infinite
        speed={500}
        slidesToShow={2}
        slidesToScroll={2}
        autoplay
        autoplaySpeed={6000}
        responsive={[
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          },
        ]}
      >
        {_.map(items, (s, index) => (
          <div key={index} className="slide-item-wrapper d-flex align-items-end position-relative">
            <Content content={s.iframe} />
          </div>
        ))}
      </Slider>
    </div>
  )
    
}