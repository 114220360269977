/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import BackgroundImage from 'gatsby-background-image'

// Images
import orangeArrow from 'img/orange_arrow.svg'

// Components
import Products from 'components/Products'
import CustomLink from 'components/CustomLink'

// CSS
import './PreviewContainer.scss'

// Third Party

const PreviewContainer = ({ content: { title, slug, description, acf: { example_image: exampleImage }}, video }) => {

  return (
    <div className={`preview-container ${video && 'withvideo'}`}>
      <div className="row position-relative">
        <div className="image-container col-12 col-lg-6">
          {video ? (
            <div className="video-container">
              <video autoPlay controls={true} muted loop playsInline id="loopVideo">
                <source src={video.video_url} type="video/mp4" />
              </video>
            </div>
          ) : (
            <BackgroundImage critical fluid={exampleImage.localFile.childImageSharp.fluid} />
          )}
        </div>
        <div className="col-12 col-lg-6" />
        <div className="products-container container">
          <div className="row h-100">
            <div className="col-12 col-lg-6 d-flex">
              {!video && (
                <div className="image-text-container pt-3 pt-lg-0">
                  <CustomLink to={`/producten/${slug}/${slug}/#a`}>
                    <h1 className="font-weight-xl pl-4 font-size-xl text-uppercase color-text-light d-flex align-items-center">
                      {title}
                      <img alt="arrow" src={orangeArrow} />
                    </h1>
                  </CustomLink>
  
                  <div className="preview-text font-size-xm color-text-main">
                    <p className="pr-lg-3 pr-2 pl-4">{description}</p>
                  </div>
                </div>
              )}
            </div>
            <div className={`col-12 col-lg-6 pt-4 pt-lg-0 ${video ? 'pl-lg-5' : ''} `}>
              <Products />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PreviewContainer
