/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import Modal from 'react-modal'

import CustomLink from 'components/CustomLink'

// Images
import KooloosPopup from 'img/kooloos-werkenbij.png'
import Close from 'img/x.svg'

import './Modal.scss'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
  },
}

function App() {
  const [modalIsOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      // eslint-disable-next-line no-lone-blocks
      {
        !sessionStorage.getItem('hide') && setIsOpen(true)
      }
    }, 2000)
  }, [])

  function closeModal() {
    sessionStorage.setItem('hide', 'true')
    setIsOpen(false)
  }

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="text-right">
          <button onClick={closeModal} type="button">
            <img src={Close} alt="" />
          </button>
        </div>
        <a href="https://werkenbij.koolooszonenhuis.nl/" rel="noreferrer" target="_blank"><img src={KooloosPopup} className="modal-image" width="650" alt="" /></a>
      </Modal>
    </div>
  )
}

export default App
