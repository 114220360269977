/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import CustomLink from 'components/CustomLink'

// CSS
import './Products.scss'

// Third Party
import _ from 'lodash'
import styled from 'styled-components'

const Products = ({ row, title }) => {
  const {
    allWordpressWpSoorten: { edges: soorten },
  } = useStaticQuery(graphql`
    {
      allWordpressWpSoorten(sort: { fields: acf___sorteernummer }) {
        edges {
          node {
            name
            slug
            path
            acf {
              image {
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <div className="products d-flex flex-wrap justify-content-center justify-content-lg-start w-100">
      {row ? (
        <div className="d-flex px-0 w-100 flex-wrap justify-content-between">
          {_.map(soorten, (soort, index) => (
            <Product row={row} key={index} soort={soort} />
          ))}
        </div>
      ) : (
        <>
          <div className="row">
            <div className="products-content-title">
              <h2 className="font-weight-xl text-center color-text-main font-size-xl">
                {title ? `${title}` : `Onze producten`}
              </h2>
            </div>
          </div>
          <div className="row w-100 px-3 px-lg-0 px-lg-0 text-center text-lg-left">
            <div className="w-100 products-content-text">
              <p className="w-100 font-size-sm color-text-contrast font-weight-xl">
                Creeër extra leefruimte of beschut en bescherm uzelf.
              </p>
            </div>
          </div>
          <div
            style={{ maxWidth: 480 }}
            className="row px-3 px-md-0 px-sm-0 px-lg-0 justify-content-start"
          >
            {_.map(soorten, (soort) => (
              <Product soort={soort} />
            ))}
          </div>
        </>
      )}
    </div>
  )
}

export default Products

const StyledImg = styled.img`
  max-height: 56px;
`

const Product = ({
  row,
  soort: {
    node: {
      name,
      slug,
      acf: {
        image: {
          localFile: { publicURL: url },
        },
      },
    },
  },
}) => (
  <CustomLink
    className={`${row
      ? `col-6 col-sm-4 col-lg-2 col-xl-1 d-flex d-lg-block`
      : `col-6 col-sm-3 d-flex d-sm-block`
      } justify-content-center`}
    to={`/producten/${slug}/${slug}`}
  >
    <div className="product-wrapper">
      <div className="product color-background-light d-flex justify-content-center flex-wrap align-items-center">
        <StyledImg alt="" src={url} />
        <h2 className="font-size-s uppercase font-family-secondary">{`${name} >`}</h2>
      </div>
    </div>
  </CustomLink>
)
