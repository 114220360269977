import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

// CSS
import 'styles/HomePage.scss'

// Components
import { MiddleText } from 'components/Texts'
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import PreviewContainer from 'components/PreviewContainer/'
import BrandSlider from 'components/Brands/BrandSlider'
import ErvaringPreviews from 'components/Ervaringen/ErvaringPreviews'
import CTABanner from 'components/Common/CTABanner'
import VideoSlider from 'components/VideoSlider'
import Modal from 'components/Modal'

// Third Party
import styled from 'styled-components'

const StyledbackgroundImage = styled(BackgroundImage)`
  height: 100%;
`

const HomePage = () => {
  const {
    wordpressPage: {
      acf: {
        banner,
        banner_1: banner1,
        banner_2: banner2,
        banner_image: {
          localFile: {
            childImageSharp: { fluid: bannerImage },
          },
        },
        content_1_title: content1title,
        content_1: content1,
        soort_relation: soortRelation,
        videos,
      },
      yoast_meta: yoast,
    },
  } = useStaticQuery(graphql`
    query MyQuery {
      wordpressPage(wordpress_id: { eq: 9 }) {
        acf {
          banner {
            video_url
          }
          banner_1 {
            banner_button_text {
              url
              title
            }
            banner_text
          }
          banner_2 {
            banner_button_text {
              url
              title
            }
            banner_text
          }
          banner_image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          content_1_title
          content_1
          soort_relation {
            acf {
              example_image {
                localFile {
                  childImageSharp {
                    fluid(quality: 100, maxWidth: 850) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              image {
                localFile {
                  publicURL
                }
              }
            }
            description
            slug
            title
          }

          videos {
            items {
              iframe
            }
          }
        }
        yoast_meta {
          name
          content
          property
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO yoast={{ meta: yoast }} />
      <div className="home-page">
        <PreviewContainer content={soortRelation} video={banner} />
        <CTABanner data={banner1} className="py-4 color-background-secondary" />
        <div className="container d-flex justify-content-center px-4 pt-5">
          <h1 className="font-size-xl font-weight-xl">{content1title}</h1>
        </div>
        <MiddleText content={content1} className="pt-0 px-4 px-lg-5 pb-4" />
        <ErvaringPreviews />
        <section className="videos">
          <VideoSlider
            items={videos.items}
            className="pb-5 container position-relative"
          />
        </section>
        <div className="banner-image-container">
          <div className="banner-image-container-inner position-relative h-100">
            <StyledbackgroundImage fluid={bannerImage} />
            <div className="gradient position-absolute w-100 h-100" />
          </div>
        </div>
        <CTABanner
          data={banner2}
          className="py-4 py-lg-5 color-background-main"
        />
        <BrandSlider title="Onze Merken" merken={false} className="py-5" />
      </div>
    </Layout>
  )
}

export default HomePage
